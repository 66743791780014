// export const TokenName = "moha_exp_access_token";
export const TokenName = process.env.REACT_APP_TOKEN_NAME;

export const TokenExpire = 60 * 60 * 24;

export const ImgStateLoadTime = 5000;

export const ProductCardTime = 5000;

export const WatermarkPrice = 10;

export const FetchTime = 1000 * 60 * 30;

export const ConsoleLog = true;

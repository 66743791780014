import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import i18n from "../../i18n";
import threeClover from "../../assets/img/clover.png";
import clover from "../../assets/img/pngwing.com (10).png";
import card from "../../assets/img/card.png";
import kakako from "../../assets/img/kakao.png";
import naver from "../../assets/img/naver.png";
import samsung from "../../assets/img/samsung.png";
import { getLocaleText } from "../../lib/util/LanguageUtil";
import { useTranslation } from "react-i18next";
import { ConsoleLog } from "../../lib/util/Common";
export const Payment = ({
  id,
  cloverMoney,
  threeCloverMoney,
  saleMoney,
  money,
  title,
  startPayment,
  onClose,
  isModalOpen,
}) => {
  const [selectedOption, setSelectedOption] = useState("creditcard");
  const currentLanguage = i18n.language;
  const { t } = useTranslation();
  // const [currentLanguage, setCurrentLanguage]= useState("en")
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };
  // if (ConsoleLog === true) {
  //   useEffect(() => {console.log(selectedOption)}, [selectedOption]);
  // }
  const location = useLocation();
  // const params = new URLSearchParams(location.search);
  const handlePayment = () => {
    onClose();
    startPayment({ product_id: id, payment_method: selectedOption });
  };

  // URL에서 전달된 파라미터 읽기
  // const id = params.get('id');
  // const cloverMoney = params.get('cloverMoney');
  // const threeCloverMoney = params.get('threeCloverMoney');
  // const saleMoney = params.get('saleMoney');
  // const money = params.get('money');
  if (!isModalOpen) return null;
  return (
    <div
      id="paymentModal"
      className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg shadow-lg w-full max-w-md mx-4 p-6 relative"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="transform transition-all duration-300 ">
          {/* 닫기 버튼 (X) */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
            aria-label="Close modal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-100">
            <div className="bg-gradient-to-r from-[#F68064] to-[#FFAA77] p-4">
              <div className="flex items-center justify-between">
                <h3 className="text-xl font-bold text-white">{title}일이삼</h3>
                {/* {id === 1 && (
              <span className="text-xs font-medium px-2 py-1 bg-white/20 rounded-full text-white">인기</span>
            )} */}
              </div>
            </div>

            {/* Content */}
            <div className="p-5">
              {/* Package Content Section */}
              <div className="bg-gray-50 rounded-lg p-4 mb-4">
                <div className="flex items-center gap-2 ">
                  <img
                    src={threeClover}
                    className="w-10 h-10"
                    alt="Three Leaf Clover"
                  />
                  <div className="flex flex-1 justify-betwee items-center">
                    <p className="text-xl text-gray-600 mr-2">{t("clover")}</p>
                    <p className="text-xl font-bold text-gray-900">
                      {threeCloverMoney}
                      {""}
                      {t("clover_item")}
                    </p>
                  </div>
                </div>

                {/* <div className="flex items-center mt-2">
              <div className="flex-1 border-t border-dashed border-gray-300"></div>
              <div className="mx-3 text-xs text-gray-500">패키지 구성</div>
              <div className="flex-1 border-t border-dashed border-gray-300"></div>
            </div>

            <div className="flex items-center gap-2 mt-3">
              <img src={clover} className="w-6 h-6" alt="Four Leaf Clover" />
              <div className="flex-1">
                <p className="text-sm text-gray-600">네잎 클로버(다운로드 권)</p>
                <p className="text-base font-bold text-gray-900">{cloverMoney}개</p>
              </div>
            </div> */}
              </div>
              <div className="grid grid-cols-2 gap-4 py-4">
                <label
                  className={`flex items-center justify-center p-4 rounded-lg cursor-pointer border hover:border-[#F68063] transition duration-30 
              ${
                selectedOption === "creditcard"
                  ? "bg-[#FFAA77] border-[#FFAA77] text-white"
                  : "bg-gray-50 border-gray-200"
              }`}
                >
                  {" "}
                  <input
                    type="radio"
                    name="creditcard"
                    value="creditcard"
                    className="hidden peer"
                    onChange={handleRadioChange}
                    checked={selectedOption === "creditcard"}
                  />
                  <div className="flex flex-col justify-center items-center">
                    <img className="w-[50px] h-[30px] mb-1" src={card} />
                    <span className="peer-checked:text-blue-500 peer-checked:font-bold">
                      신용카드
                    </span>
                  </div>
                </label>

                <label
                  className={`flex items-center justify-center p-4 rounded-lg cursor-pointer border hover:border-[#F68063] transition duration-30 
        ${
          selectedOption === "kakaopay"
            ? "bg-[#FFAA77] border-[#FFAA77] text-white"
            : "bg-gray-50 border-gray-200"
        }`}
                >
                  {" "}
                  <input
                    type="radio"
                    name="kakaopay"
                    value="kakaopay"
                    onChange={handleRadioChange}
                    checked={selectedOption === "kakaopay"}
                    className="hidden peer"
                  />
                  <div className="flex flex-col justify-center items-center">
                    <img className="w-[80px] h-[30px] mb-1" src={kakako} />
                    <span className="peer-checked:text-blue-500 peer-checked:font-bold">
                      카카오페이
                    </span>
                  </div>
                </label>

                <label
                  className={`flex items-center justify-center p-4 rounded-lg cursor-pointer border hover:border-[#F68063] transition duration-30 
        ${
          selectedOption === "samsungpay"
            ? "bg-[#FFAA77] border-[#FFAA77] text-white"
            : "bg-gray-50 border-gray-200"
        }`}
                >
                  {" "}
                  <input
                    type="radio"
                    name="samsungpay"
                    value="samsungpay"
                    onChange={handleRadioChange}
                    checked={selectedOption === "samsungpay"}
                    className="hidden peer"
                  />
                  <div className="flex flex-col justify-center items-center">
                    <img className="w-[90px] h-[35px] mb-1" src={samsung} />
                    <span className="peer-checked:text-blue-500 peer-checked:font-bold">
                      삼성페이
                    </span>
                  </div>
                </label>

                <label
                  className={`flex items-center justify-center p-4 rounded-lg cursor-pointer border hover:border-[#F68063] transition duration-30 
        ${
          selectedOption === "naverpay"
            ? "bg-[#FFAA77] border-[#FFAA77] text-white"
            : "bg-gray-50 border-gray-200"
        }`}
                >
                  {" "}
                  <input
                    type="radio"
                    name="naverpay"
                    value="naverpay"
                    onChange={handleRadioChange}
                    checked={selectedOption === "naverpay"}
                    className="hidden peer"
                  />
                  <div className="flex flex-col justify-center items-center">
                    <img className="w-[80px] h-[30px] mb-1" src={naver} />
                    <span className="peer-checked:text-blue-500 peer-checked:font-bold">
                      네이버페이
                    </span>
                  </div>
                </label>
              </div>

              {/* Price Section */}
              <div className="flex items-center justify-between mb-4">
                <div className="flex flex-col">
                  <div
                    style={{
                      display: `flex`,
                      alignItems: `center`,
                    }}
                  >
                    <span className="flex text-2xl mr-2 text-red-500">
                      {Math.round(100 - (saleMoney / money) * 100)}%
                    </span>
                    <span
                      style={{ fontFamily: "DefaultFont" }}
                      className="text-xl text-gray-500 line-through"
                    >
                      {" "}
                      {getLocaleText("₩", "$", currentLanguage)}
                      {money.toLocaleString()}
                    </span>
                  </div>
                  <span
                    style={{ fontFamily: "DefaultFont" }}
                    className="text-2xl font-bold text-[#F68064]"
                  >
                    {" "}
                    {getLocaleText("₩", "$", currentLanguage)}
                    {saleMoney.toLocaleString()}
                  </span>
                </div>
                <button
                  onClick={() => handlePayment()} // 결제 시작
                  className="px-6 py-2 bg-[#F68064] text-white rounded-lg font-medium text-sm
                           transform transition-all duration-300 hover:bg-[#FFAA77] 
                           focus:outline-none focus:ring-2 focus:ring-[#F68064] focus:ring-offset-2"
                >
                  구매하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
